import {Site} from '@hconnect/apiclient'
import {useQuery} from 'react-query'

import {api} from '../Invite/Api/Api'

const fetchSites = async ({queryKey}) => {
  const [endpoint, {customerId}] = queryKey

  const queryParams = new URLSearchParams({customerId})

  const roleRequests = await api.get<Site[]>(`/${endpoint}?${queryParams.toString()}`)

  return roleRequests?.data
}

export const useSites = (customerId: string | undefined, sitesIds: string[], enabled: boolean) => {
  const sitesQueryInfo = useQuery(['sites', {customerId}], fetchSites, {
    enabled
  })

  const sites =
    sitesQueryInfo.data?.map((site) => ({
      ...site,
      siteName: site.siteName
        .replace(/\{([^}]+)}/g, '$1')
        .replace(/(\w+)\s+(\w+)/g, '$1, $2')
        .replace(/[{}]/g, '')
    })) ?? []

  const selectedSites = sitesIds.map((siteId) => sites.find((site) => site.siteId === siteId))

  return {
    isSitesLoading: sitesQueryInfo.isLoading,
    sites,
    selectedSites
  }
}
